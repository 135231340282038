import { TextField } from "@mui/material";
import React from "react";

export const SearchAddress = ({ setAddressSearch }) => {
  return (
    <React.Fragment>
      <TextField
        size="small"
        variant="outlined"
        sx={{ marginBottom: 2, bgcolor: "white" }}
        fullWidth
        onChange={(e) => setAddressSearch(e.target.value)}
        placeholder="Serch Address..."
      />
    </React.Fragment>
  );
};
