import { login } from "../service";
import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const LogIn = () => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [mobile, setMobile] = useState("7058657111");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [errors, setError] = useState(null);
  const handleLogin = async () => {
    const payload = { mobile, password };
    try {
      const result = await login(payload);
      setToken(result);
      sessionStorage.setItem("token", result.accessToken);
      const tkn = sessionStorage.getItem("token");
      tkn ? navigate("/") : navigate("/login");
      setError(null);
    } catch (err) {
      setError("Incorrect mobile or password");
    }
  };

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    setTimeout(() => {
      setError(null);
    }, 10000);
  }, [errors]);
  useEffect(() => {
    ref.current.focus();
  }, []);
  return (
    <div style={{ height: "85.2vh" }}>
      <Grid container spacing={2}>
        <Grid item xl={4.5} lg={4.5} md={10} sm={12} xs={12}></Grid>
        <Grid item xl={3} lg={3} md={10} sm={12} xs={12}>
          <Card className="login-card">
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xl={4} lg={12} md={10} sm={12} xs={12}>
                  <Grid container>
                    <Grid item xs={8}>
                      <span style={{ fontSize: "40px" }}>Login</span>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Mobile No"
                    required
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    error={errors}
                    inputRef={ref}
                  />
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel
                      error={errors}
                      required
                      htmlFor="outlined-adornment-password"
                    >
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? "text" : "password"}
                      required
                      value={password}
                      error={errors}
                      onChange={(e) => setPassword(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  {errors && <Alert severity="error"> {errors} </Alert>}
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Button
                    sx={{ bgcolor: "#008E97" }}
                    fullWidth
                    variant="contained"
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Button variant="outlined" fullWidth color="warning">
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
