import React, { useState } from "react";
import Select from "react-select";

const AddBizRecurring = ({handleChangeBizType, selOptionBiz}) => {
  
  const options = [
    { value: true, label: "True" },
    { value: false, label: "False" },
  ];
  
  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 1000,
    }),
  };
  return (
    <React.Fragment>
      <Select
        value={selOptionBiz}
        onChange={handleChangeBizType}
        options={options}
        
        theme={(theme) => ({
          ...theme,
          borderRadius: 3,

          colors: {
            ...theme.colors,
            primary25: "",
            primary: "#008E97",
          },
        })}
        styles={customStyles}
      />
    </React.Fragment>
  );
};

export default AddBizRecurring;
