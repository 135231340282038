import React,{useEffect, useState} from "react";
import { Grid,Snackbar,Button,Stack } from '@mui/material';
import MuiAlert from "@mui/material/Alert";

import UploadItem from "./UploadItem";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Uploads2 = () => {
  const [open, setOpen] = useState(false);
  const [show,setShow]=useState(false)


  const handleClick = (message) => {
    setShow(message);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setOpen(!!show);
  }, [show]);

  
  return (
    <>
   

    <Grid container spacing={2} sx={{ display: "flex", flexWrap: "wrap" }}>
      <Grid item xs={12}>
      <Stack spacing={2} sx={{ width: "100%" }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={show ==="The file was uploaded successfully."? "success" : "error"} sx={{ width: "100%" }}>
                {show}
              </Alert>
            </Snackbar>
          </Stack>
      </Grid>
      <UploadItem setShow={handleClick} uploadname="Photo" title="Photo Upload"   />
      <UploadItem  setShow={handleClick} uploadname="Aadhar" title="Aadhar Upload"    />
      <UploadItem setShow={handleClick} uploadname="Pan" title="Pan Upload"   />
      <UploadItem setShow={handleClick} uploadname="Adhaarudyog" title="Udyog Upload"   />
    </Grid>
    </>
  );
};

export default Uploads2;

