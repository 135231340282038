import {  TextField } from "@mui/material";
import React from "react";

export const SearchBox = ({ setTxtSearch }) => {
  return (
    <React.Fragment>
        <TextField
          variant="outlined"
          size="small"
          sx={{ marginBottom: 2, bgcolor: "white" }}
          fullWidth
          onChange={(e) => setTxtSearch(e.target.value)}
          placeholder="Serch Name..."
        />
    </React.Fragment>
  );
};
