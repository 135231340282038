import { postUploadAPI } from "../helper";


export  const upLoadFileToCloud = async (obj) => {

    // const originalFile = new FormData();
    // const dt = new Date().toLocaleDateString("de-DE");
    // acceptedFiles.forEach((file) => {
    //   originalFile.append("file", file, `${fileType}_${subsId}_${dt}.jpg`);
    // });
    const result = await postUploadAPI("/uploaddocs", obj);
    return result;
  };
