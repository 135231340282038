import React from "react";
import Select from "react-select";

export const SearchWardName = ({ setWardSearch }) => {
	const WardNameOpt = [
    {value:"" ,label:"Select All"},
		{ value: "Prabhag 18", label: "Prabhag 18" },
		{ value: "Prabhag 13", label: "Prabhag 13" },
	];

	const customStyles = {
		menu: (base) => ({
			...base,
			zIndex: 1000,
			color: "black",
		}),
	};

	return (
		<React.Fragment>
				<Select
					onChange={(e) => setWardSearch(e.value)}
					placeholder="Select WardName"
					theme={(theme) => ({
						...theme,
						borderRadius: 3,
						colors: {
							...theme.colors,
							primary25: "gray",
							primary: "#008E97",
						},
					})}
					styles={customStyles}
					options={WardNameOpt}
				/>
		</React.Fragment>
	);
};
