import React from "react";
import Select from "react-select";

export const SearchBiz = ({ setBizSearch ,options }) => {

  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 1000,
      color: "black",
    }),
  };

  return (
    <React.Fragment>
        <Select
          placeholder="Select BizType..."
          theme={(theme) => ({
            ...theme,
            borderRadius: 3,
            colors: { ...theme.colors, primary25: "gray", primary: "#008E97" },
          })}
          onChange={(e) => setBizSearch(e.value)}
          styles={customStyles}
          options={options}
        />
    </React.Fragment>
  );
};
