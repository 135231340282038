import { postSecureAPI } from "../helper";

export const addbiz = async (data) => {
  const parentsubsid = "64e3def306988d1b7e5b4f4e";
  const payload = {
    ...data,
    parentsubsid,
  };
  const result = await postSecureAPI("/addbiz", payload);
  console.log("---result--->", result);
  return result;
};
