import { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { carasouledata } from "./Data/navdata";

export const Carasoules = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      style={{ marginTop: "-15px" }}
      activeIndex={index}
      onSelect={handleSelect}
    >
      {carasouledata.map((item) => (
        <Carousel.Item>
          <img
            style={{ width: "100%", height: "550px" }}
            src={item.image}
            alt=""
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
