import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  Grid,
  Button,
  Pagination,
  Stack,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableCell,
} from "@mui/material";
import { streetvendorAll, allbiz } from "../service";
import { AllVendorItem } from "./AllVendorItem";
import { AddBizType } from "./AddBizType";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { SearchBox } from "./SearchBox";
import { SearchBiz } from "./SearchBiz";
import { SearchAddress } from "./SearchAddress";
import { SearchWardName } from "./SearchWardName";

export const AllVendors = ({ handleSearch }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [origData, setOrigData] = useState([]);
  const [options, setOptions] = useState([]);
  const [wardNameOptions, setWardNameOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [txtSearch, setTxtSearch] = useState("");
  const [bizSearch, setBizSearch] = useState("");
  const [wardSearch, setWardSearch] = useState("");
  const [addressSearch, setAddressSearch] = useState("");

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const getVendors = async () => {
    const payload = {
      parentsubsid: "184bf54b8edc4df996080fadaad498ea",
    };
    const allVendorData = await streetvendorAll(payload);
    setData(allVendorData);
    setOrigData(allVendorData);
    console.log("Data ALLVender========>", allVendorData);
  };

  const getVendorOption = async () => {
    const allVendorData = await allbiz();
    const optData = allVendorData.map((item) => ({
      value: item._id,
      label: item.name,
    }));
    const optDataAll = [{ value: "", label: "Select All" }, ...optData];

    setOptions(optDataAll);
    console.log("DropDown===Update=====>", allVendorData);
  };
  console.log(options);

  const getWardName = async () => {
    const payload = {
      parentsubsid: "184bf54b8edc4df996080fadaad498ea",
    };
    const allVendorData = await streetvendorAll(payload);
    const wardNameoptData = allVendorData.map((item) => ({
      value: item._id,
      label: item.wardName,
    }));
    setWardNameOptions(wardNameoptData);
  };

  console.log("DropDown===Update=====>", wardNameOptions);

  console.log(data);

  const productsPerPage = 5;
  const totalPages = Math.ceil(data.length / productsPerPage);
  const startIndex = (currentPage - 1) * productsPerPage;
  const endIndex = startIndex + productsPerPage;
  const Data = data.slice(startIndex, endIndex);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const filtered = origData.filter((item) => {
      const upperCaseSearchValue = txtSearch.toUpperCase();
      return item.fullName.toUpperCase().includes(upperCaseSearchValue);
    });
    const nxtFiltered =
      bizSearch === ""
        ? filtered
        : filtered.filter((item) => bizSearch === item.bizType);

    const WardFilt =
      wardSearch === ""
        ? nxtFiltered
        : nxtFiltered.filter((item) => wardSearch === item.wardName);

    const upperCaseSearchValue = addressSearch.toUpperCase();
    const AddSrh =
      upperCaseSearchValue === ""
        ? WardFilt
        : WardFilt.filter((item) => {
            return (
              item.wardName.toUpperCase().includes(upperCaseSearchValue) ||
              item.address.toUpperCase().includes(upperCaseSearchValue) ||
              item.landMark.toUpperCase().includes(upperCaseSearchValue) ||
              item.roadName.toUpperCase().includes(upperCaseSearchValue)
            );
          });

    setData(AddSrh);
  }, [txtSearch, bizSearch, wardSearch, addressSearch]);

  useEffect(() => {
    getVendors();
    getVendorOption();
    getWardName();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <SearchBox setTxtSearch={setTxtSearch} />
      </Grid>
      <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
        <SearchBiz options={options} setBizSearch={setBizSearch} />
      </Grid>
      <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
        <SearchWardName
          setWardSearch={setWardSearch}
          options={wardNameOptions}
        />
      </Grid>
      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
        <SearchAddress setAddressSearch={setAddressSearch} options={origData} />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>EnrollmentNo</TableCell>
                <TableCell align="left">FullName</TableCell>
                <TableCell align="left">Gender</TableCell>
                <TableCell align="left">WardName</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="left">Biz Type</TableCell>
                <TableCell align="left">
                  {" "}
                  <Button onClick={handleClickOpen} variant="conrtained">
                    <AddCircleOutlineIcon />
                  </Button>
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <AddBizType
              handleClose={handleClose}
              open={open}
              getVendorOption={getVendorOption}
            />
            <TableBody className={Data.length === 0 && "table-body-loader"}>
              {Data.length === 0 ? (
                <React.Fragment>
                  <div class="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </React.Fragment>
              ) : (
                Data.map((item) => (
                  <React.Fragment>
                    <AllVendorItem
                      handleSearch={handleSearch}
                      item={item}
                      options={options}
                      key={item._id}
                      getVendors={getVendors}
                    />
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Grid
          spacing={3}
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="secondary"
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
