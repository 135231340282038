import { Card, CardContent } from "@mui/material";
import "./Components/common.css";
import { Provider } from "react-redux";
import { configStore } from "./store/configStore";
import "bootstrap/dist/css/bootstrap.min.css";
import { Landing } from "./Components/Landing";

function App() {
  const myStore = configStore();
  return (
    <Card
      className="app"
      sx={{
        bgcolor: "#c7f5f764",
        height: "100%",
        width: "100%",
        overflow: "auto",
     
      }}
    >
      <CardContent>
        <Provider store={myStore}>
          {/* <Navigation /> */}
          {/* <NavBar/> */}
          <Landing/>
        </Provider>
      </CardContent>
    </Card>
  );
}

export default App;
