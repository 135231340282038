import { Card, CardContent, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { homeroutedata } from "./Data/navdata";
import { Carasoules } from "./Carasoules";
export const Home = () => {
  const navigate = useNavigate();
 
  return (
    <div>
      <Carasoules />
      <br />
      <Grid container spacing={2}>
        <Grid item xl={9} lg={9} md={9} sm={7} xs={7}>
          <Grid container spacing={2}>
            {homeroutedata.map((item) => (
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <Card
                  onClick={() => navigate(item.path)}
                  sx={{ height: "250px" }}
                >
                  <CardContent>
                    <span className="home-title-name">{item.title} </span>{" "}
                    <span className="home-title-name"> {item.icon}</span>{" "}
                    <p>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                      Eligendi corporis iste neque deserunt voluptatum soluta
                      velit voluptates tempora in a!
                    </p>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={5} xs={5}>
          <Card sx={{ height: "100vh" }}>
            <CardContent>
              <marquee
                height="700px"
                scrollamount="15"
                behavior="scroll"
                direction="down"
              >
                <img
                  width="100%"
                  src="https://m.media-amazon.com/images/I/51o+YLEy79L._AC_UF1000,1000_QL80_.jpg"
                  alt=""
                />
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Beatae voluptas eius laboriosam possimus aliquid cupiditate
                  laborum nam iusto modi, deleniti sapiente rerum necessitatibus
                  repellat atque amet. Cum dignissimos repellendus ipsa earum
                  similique accusantium illo eius atque sunt, ea dolor eveniet
                  cumque eaque numquam ipsum pariatur. Pariatur, totam porro.
                  Reprehenderit, error.
                </p>
              </marquee>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
