import { Button, Card, CardContent, Grid } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import VerifiedIcon from "@mui/icons-material/Verified";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Uploads2 from "./Uploads2";
import DownloadIcon from '@mui/icons-material/Download';

const AllVendorDetail = () => {
  const reduxvender = useSelector((state) => state.venderReducer.vender);
  const [data, setData] = useState(reduxvender);
  console.log("REciveddata", reduxvender);
  return (
    <Grid container spacing={2}>
      {data.map((item) => (
        <>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card className="detailsvender">
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    item
                    xl={3}
                    lg={3}
                    md={8}
                    sm={12}
                    xs={12}
                  >
                    <img
                      style={{
                        borderRadius: "50%",
                        border: "5px solid #15595e",
                        textAlign: "center",
                      }}
                      width="60%"
                      src="dummyicon.webp"
                      alt=""
                    />{" "}
                  </Grid>
                  <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                    <span className="vender-name">
                      {" "}
                      {item.fullName} <VerifiedIcon sx={{ fontSize: "60%" }} />{" "}
                    </span>{" "}
                    <br />
                    <span> ( Flower Vender ) </span>
                    <p> </p>
                    <p> {item.mobile} </p>
                    <p>
                      {" "}
                      {item.wardName}, {item.roadName}, {item.landMark},
                      {item.address}{" "}
                    </p>
                    {/* <p></p> */}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <Uploads2 />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12} >
            <Card sx={{ height: "100%" }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={12} lg={12} md={12} sm={12} xs={12}
                    style={{margin:'10px'}}
                  >
                 <span >

                    <DownloadIcon
                     sx={{
                      width:60,
                      height:60,
                       fontSize: "30px",
                       color: "#008e97",
                       padding:'10px',
                      border:'1px dashed #008e97',
                       borderRadius:'50%'
                      }}
                      />
                    <span style={{fontSize:'30px',fontWeight:400,marginLeft:'20px'}}>  Certificate</span>
                      </span>
                  </Grid>
                
                  <Grid
                    item
                    xl={12} lg={12} md={12} sm={12} xs={12}
                    style={{margin:'10px'}}
                  >
                 <span >

                    <DownloadIcon
                     sx={{
                      width:60,
                      height:60,
                       fontSize: "30px",
                       color: "#008e97",
                       padding:'10px',
                      border:'1px dashed #008e97',
                       borderRadius:'50%'
                      }}
                      />
                    <span style={{fontSize:'30px',fontWeight:400,marginLeft:'20px'}}>  Pay Receipt</span>
                      </span>
                  </Grid>
                 
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Button
                      startIcon={<DoneAllIcon />}
                      fullWidth
                      variant="contained"
                    >
                      Verification
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Button
                      startIcon={<CurrencyRupeeIcon />}
                      fullWidth
                      color="warning"
                      variant="contained"
                    >
                      Payment
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      ))}
    </Grid>
  );
};

export default AllVendorDetail;
