export const baseAuthUrl = "https://api.pickupbiz.in/api";
export const baseUrl = "https://api.pickupbiz.in/sv";
export const streetven = "https://api.pickupbiz.in/sv/addstreetvendor";
export const allbizz = "https://api.pickupbiz.in/sv/allbizdd";

export const authTokenHeader = () => {
  const headerAuth = {
    headers: { Authorization: `${sessionStorage.getItem("token")}` },
  };
  return headerAuth;
};

// export const parentsubsid = sessionStorage.getItem("parentsubsid");
