import React, { useState, useEffect } from "react";
import {  Grid, Button } from "@mui/material";
import { read, utils } from "xlsx";
import { streetvendorAddAll } from "../service/streetvendor/streetven";

export const StreetVendorUploadList = ({
  ContainerClassName = "",
  InputFileClassName = "",
  ContainerButtonsClassName = "",
  ConvertButtonClassName = "",
  CleanButtonsClassName = "",
  ConvertButtonText = "Convert",
  CleanButtonText = "Clean",
  JsonDataSetter,
}) => {
  const [selectedFile, setSelectedFile] = useState("");
  const [res, setRes] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [txt, setTxt] = useState("");
  const [subs, setSubs] = useState([]);
  const [subsFilt, setSubsFilt] = useState([]);
  const [JsonData, setJsonData] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  let data = [
    {
      name: "jayanth",
      data: "scd",
      abc: "sdef",
    },
  ];
  const OnchangeValue = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const CleanValues = () => {
    setSelectedFile("");
    document.getElementById("excel-to-json-input-file").value = "";
  };
  const onSubmitXLS = () => {
    utils.json_to_sheet(data, "out.xlsx");
    if (selectedFile) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = read(data, { type: "binary" });
        const FinalData = workbook.SheetNames.map((sheet) => {
          let rowObject = utils.sheet_to_row_object_array(
            workbook.Sheets[sheet]
          );
          return rowObject;
        });
        console.log("===---===-->>", FinalData[0]);
        setJsonData(FinalData[0]);
      };
    }
  };
  const handleData = async () => {
    setIsSubmitted(true);
    const size = 300;
    const arrayOfArrays = [];
    for (let i = 0; i < JsonData.length; i += size) {
      arrayOfArrays.push(JsonData.slice(i, i + size));
    }
    // const subsid = selectedCustomer[0].subsid;
    arrayOfArrays.map(async (arrData) => {
      const data = {
        parentsubsid: "184bf54b8edc4df996080fadaad498ea",
        arrData,
      };

      console.log("=====data=====>", data);
      const result = await streetvendorAddAll(data);
      console.log("----->", result);
      //   const result = await axios.post(
      //     `${baseUrl}/commodityorderlists/addall`,
      //     data
      //   );
      //   setRes(result.data);
    });
  };
  const handleSelectedCust = (item) => {
    console.log(item);
    setSelectedCustomer([item]);
  };
  const getSubs = async () => {
    // const result = await axios.get(`${baseUrl}/subscriber/all`);
    // console.log(result.data);
    // setSubs(result.data);
  };

  useEffect(() => {
    const result = subs.filter((item) =>
      item.companyname.toUpperCase().includes(txt.toUpperCase())
    );
    console.log(result);
    setSubsFilt(result);
  }, [txt]);

  useEffect(() => {
    getSubs();
  }, []);
  return (
    <div className="containercname">
      <Grid container spacing={3}>
        {/* {selectedCustomer.length === 0 ? (
          <Grid item xs={3}>
            <TextField
              variant="outlined"
              fullWidth
              label="Enter Company Name"
              onChange={(e) => setTxt(e.target.value)}
            />
            <Grid container spacing={1}>
              {subsFilt &&
                subsFilt.map((item) => (
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => handleSelectedCust(item)}
                    >
                      {item.mobile} - {item.companyname}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={3}>
            {selectedCustomer.length > 0 && (
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setSelectedCustomer([])}
              >
                {selectedCustomer[0].mobile} - {selectedCustomer[0].companyname}
              </Button>
            )}
          </Grid>
        )} */}

        <Grid item xs={2}>
          <input
            onChange={OnchangeValue}
            id="excel-to-json-input-file"
            className={InputFileClassName}
            type="file"
            accept=".xls,.xlsx"
          />
        </Grid>
        <Grid item xs={2}>
          <div className={`${ContainerButtonsClassName}`}>
            {selectedFile && (
              <Button
                variant="contained"
                className={ConvertButtonClassName}
                onClick={onSubmitXLS}
              >
                {ConvertButtonText}
              </Button>
            )}
          </div>
          <p>To be upload Data: {JsonData.length}</p>
          <p>Uploaded Data: {JSON.stringify(res)}</p>
        </Grid>
        <Grid item xs={2}>
          {selectedFile && (
            <Button
              variant="contained"
              className={CleanButtonsClassName}
              onClick={CleanValues}
            >
              {CleanButtonText}
            </Button>
          )}
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            disabled={isSubmitted}
            onClick={handleData}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
