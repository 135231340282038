import React, { useEffect, useState } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Toolbar,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SignpostIcon from "@mui/icons-material/Signpost";

import {
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  IconButton,
  ListItemText,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

const PAGES = ["home", "registration", "vendors", "logout"];

export const NavBar = () => {
  const navigate = useNavigate();
  const [isTokenPresent, setIsTokenPresent] = React.useState(
    !!sessionStorage.getItem("token")
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [value, setValue] = useState();
  const theme = useTheme();
  console.log(theme);
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  console.log(isMatch);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    setIsTokenPresent(!!token);

    if (!token) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <React.Fragment>
      <AppBar className="main-nav">
        <Toolbar>
          <SignpostIcon />{" "}
          {isMatch ? (
            <>
              <Typography sx={{ fontSize: "1.5rem", paddingLeft: "10%" }}>
                Street Vender
              </Typography>
              <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
                <List>
                  {PAGES.map((page, index) => (
                    <Link
                      style={{ textDecoration: "none" }}
                      to={page == "home" ? `/` : `/${page}`}
                    >
                      <ListItemButton
                        onClick={() => setOpenDrawer(false)}
                        key={index}
                      >
                        <ListItemIcon>
                          <ListItemText> {page.toUpperCase()}</ListItemText>
                        </ListItemIcon>
                      </ListItemButton>
                    </Link>
                  ))}
                </List>
              </Drawer>
              {isTokenPresent && (
                <IconButton
                  sx={{ color: "white", marginLeft: "auto" }}
                  onClick={() => setOpenDrawer(!openDrawer)}
                >
                  <MenuIcon />
                </IconButton>
              )}{" "}
            </>
          ) : (
            <>
              {isTokenPresent && (
                <Tabs
                  sx={{ marginLeft: "auto" }}
                  textColor="inherit"
                  value={value}
                  onChange={(e, value) => setValue(value)}
                >
                  {PAGES.map((page, index) => (
                    <Link
                      style={{ textDecoration: "none", color: "white" }}
                      to={page == "home" ? `/` : `/${page}`}
                    >
                      <Tab key={index} label={page} />
                    </Link>
                  ))}
                </Tabs>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};
