import React, { useEffect, useState } from "react";
import Select from "react-select";
import { updatebiz } from "../service";
import { Button, TableCell, TableRow } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const AllVendorItem = ({ item, options, getVendors }) => {
  const navigate = useNavigate();
  const disptach = useDispatch();
  const [selOption, setSelOption] = useState([]);

  const handleChangeBiz = async (e, curr) => {
    const bizType = e.value;
    const _id = curr._id;
    const payload = {
      bizType,
      _id,
    };

    await updatebiz(payload);
    getVendors();
  };
  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 1000,
    }),
  };

  const handlevenderview = (item) => {
    const type = "ADDVENDER";
    const payload = item;
    const action = { type, payload };
    disptach(action);
  };

  useEffect(() => {
    const filteredOpt = options.filter((curr) => curr.value === item.bizType);
    setSelOption(filteredOpt);
  }, [options, item.bizType]);

  return (
    <TableRow
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      key={item._id}
      onClick={() => handlevenderview(item)}
    >
      <TableCell component="th" scope="row">
        {item.enrollmentNo}
      </TableCell>
      <TableCell
        style={{ cursor: "pointer" }}
        onClick={() => navigate("/allvendordetail")}
        width={200}
        align="left"
      >
        {item.fullName}
      </TableCell>
      <TableCell align="left">
        {" "}
        {item.gender === "M" ? "Male" : "Female"}
      </TableCell>
      <TableCell width={110} align="left">
        {item.wardName}
      </TableCell>
      <TableCell onClick={() => navigate("/allvendordetail")} align="left">
        {`${item.landMark}, ${item.roadName}, ${item.address}`}
      </TableCell>
      {selOption.map((item) => (
        <TableCell width={160} align="left">
          {item.label}
        </TableCell>
      ))}

      <TableCell align="left">
        <Select
          styles={customStyles}
          options={options}
          value={selOption}
          onChange={(e) => handleChangeBiz(e, item)}
        />
      </TableCell>
      <TableCell align="left">
        {" "}
        <Button
          startIcon={<CurrencyRupeeIcon />}
          color="warning"
          variant="contained"
        >
          PAY
        </Button>{" "}
      </TableCell>
    </TableRow>
  );
};
