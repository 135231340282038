import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
} from "@mui/material";
import { addbiz } from "../service";
import AddBizRecurring from "./AddBizRecurring";

export const AddBizType = ({ handleClose, open, getVendorOption }) => {
  const [formData, setFormData] = useState({
    name: "",
    basecharges: "",
    discountinpercent: "",
    recurring: "",
    totaldays: "",
    reminder1: "",
    reminder2: "",
    reminder3: "",
  });
  const [selOptionBiz, setSelOptionBiz] = useState({ value: true, label: "True" });

  const handleChangeBizType = (selectedOption) => {
    setSelOptionBiz(selectedOption);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleAdd = async () => {
    await addbiz(formData);
    handleClose();
    getVendorOption();
  };

  useEffect(()=>{
    setFormData({
      ...formData,
      recurring: selOptionBiz.value
    });
  },[selOptionBiz])
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ color: "#008E97" }}>Add Business Type</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <TextField
                  label="Business Name"
                  variant="outlined"
                  onChange={handleChange}
                  name="name"
                  value={formData.name}
                  fullWidth
                
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Basecharges"
                  variant="outlined"
                  onChange={handleChange}
                  name="basecharges"
                  value={formData.basecharges}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Discount"
                  variant="outlined"
                  onChange={handleChange}
                  name="discountinpercent"
                  value={formData.discountinpercent}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <AddBizRecurring handleChangeBizType={handleChangeBizType} selOptionBiz={selOptionBiz} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Total Days"
                  variant="outlined"
                  onChange={handleChange}
                  name="totaldays"
                  value={formData.totaldays}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Reminder1"
                  variant="outlined"
                  onChange={handleChange}
                  name="reminder1"
                  value={formData.reminder1}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Reminder2"
                  variant="outlined"
                  onChange={handleChange}
                  name="reminder2"
                  value={formData.reminder2}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="Reminder3"
                  variant="outlined"
                  onChange={handleChange}
                  name="reminder3"
                  value={formData.reminder3}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button
              onClick={handleAdd}
              variant="contained"
              sx={{ marginTop: 2, bgcolor: "#008E97" }}
              fullWidth
            >
              Add
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
