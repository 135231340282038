import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonIcon from "@mui/icons-material/Person";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import BallotIcon from "@mui/icons-material/Ballot";
import PolylineIcon from "@mui/icons-material/Polyline";
import PanoramaPhotosphereIcon from "@mui/icons-material/PanoramaPhotosphere";
import SchemaIcon from "@mui/icons-material/Schema";

export const homeroutedata = [
  {
    title: "AllVender",
    path: "/allvendors",
    icon: <PersonIcon sx={{ fontSize: "100%" }} />,
  },
  {
    title: "Hocke Zone",
    path: "/allvender",
    icon: <PanoramaPhotosphereIcon sx={{ fontSize: "100%" }} />,
  },
  {
    title: "Data List",
    path: "/allvender",

    icon: <BallotIcon sx={{ fontSize: "100%" }} />,
  },
  {
    title: "Sche",
    path: "/allvender",
    icon: <SchemaIcon sx={{ fontSize: "100%" }} />,
  },
  {
    title: "Insurance",
    path: "/allvender",
    icon: <PolylineIcon sx={{ fontSize: "100%" }} />,
  },
  {
    title: "Certificate",
    path: "/allvender",
    icon: <WorkspacePremiumIcon sx={{ fontSize: "100%" }} />,
  },
  {
    title: "Registration",
    path: "/registration",
    icon: <PersonAddAlt1Icon sx={{ fontSize: "100%" }} />,
  },
];

export const carasouledata=[
  {
    image:'image/img0.jpg'
  },
  {
    image:'image/img1.jpg'
  },
  {
    image:'image/img2.jpg'
  },
  {
    image:'image/img3.jpg'
  },
  {
    image:'image/img4.jpg'
  },
  {
    image:'image/img5.jpg'
  },
  {
    image:'image/img6.jpg'
  },
  {
    image:'image/img7.jpg'
  },
  {
    image:'image/img8.jpg'
  },
  {
    image:'image/img9.jpg'
  },
]