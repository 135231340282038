import React, { useState,useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Grid,Card,CardContent,Button, Alert } from '@mui/material';
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import fs from "./Db.json";
import { upLoadFileToCloud } from "../service";
import { useSelector } from "react-redux";
import "./common.css"

const UploadItem = ({uploadname,title,setShow}) => {
    const [userData, setUserData] = useState(null);
    const [laod,setLoad]=useState(false)
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const reduxvdner=useSelector((state)=>state.venderReducer.vender)
    const parentid=reduxvdner.map((item)=>item.parentsubsid)
    
    const handleUpload = async (fileType) => {
        setLoad(true)
        const originalFile = new FormData();
        const dt = new Date().toLocaleDateString("de-DE");
        acceptedFiles.forEach((file) => {
          originalFile.append("file", file, `${fileType}_${parentid}_${dt}.jpg`);
        });

        setTimeout( async()=>{
            const result = await upLoadFileToCloud(originalFile)
            setShow(result?"The file was uploaded successfully.":"Sorry,file has failed to upload");
            setLoad(false);
          },5000 )
      };
    
      

      useEffect(() => {
        setUserData(fs);
      }, []);
    
    return (
        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
        <Card {...getRootProps({ className: "file-uploadcard" })}>
          <CardContent>
            {
                laod?<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
:
            <Button onClick={() => handleUpload(uploadname)}>
              <CloudUploadOutlinedIcon
                sx={{ fontSize: "5rem", borderBottom: "1px dashed black" }}
                />
              <input {...getInputProps()} />
            </Button>
            
        }
            <h2 className="upload-item-h2">{title}</h2>
          </CardContent>
        </Card>
      </Grid>
    );
};

export default UploadItem;