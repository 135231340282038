export const venderReducer = (state = { vender: [] }, action) => {
  switch (action.type) {
    case "ADDVENDER":
      return {
        ...state,
        vender: [action.payload],
      };
  }
  return state;
};
