import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./Home";
import { LogIn } from "./LogIn";
import { Registration } from "./Registration";
import { StreetVendorUploadList } from "./StreetVendorUploadList";
import { AllVendors } from "./AllVendors";
import AllVendorDetail from "./AllVendorDetail";
import { NavBar } from "./NavBar";
import Logout from "./Logout";

export const Landing = () => {
  return (
    <BrowserRouter>
      <NavBar />
      <br />
      <br />
      <br />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/uploadsv" element={<StreetVendorUploadList />} />
        <Route path="/vendors" element={<AllVendors />} />
        <Route path="/allvendordetail" element={<AllVendorDetail />} />
        <Route path="/logout" element={<Logout />} />
      </Routes>
    </BrowserRouter>
  );
};
