  import { postAPI } from "../helper";

export const streetven = async (obj) => {
  const result = await postAPI("/addstreetvendor", obj);
  return result;
};

export const streetvendorAddAll = async (obj) => {
  const result = await postAPI("/addallstreetvendor", obj);
  return result;
};

export const streetvendorAll = async (obj)=>{
  const result = await postAPI("/allstreetvendor", obj);
  
  return result;
}