import React, { useEffect, useRef, useState } from "react";
import {
  FormLabel,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
} from "@mui/material";
import Select from "react-select";
import { allbiz } from "../service";
import { streetven } from "../../src/service/streetvendor/streetven";
import { useNavigate } from "react-router-dom";

export const Registration = () => {
  const ref = useRef(null);

  const [parentsubsid, setParentsubsid] = useState("");
  const [enrollmentNo, setEnrollmentNo] = useState("");
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const [wardName, setWardName] = useState("");
  const [roadName, setRoadName] = useState("");
  const [landMark, setLandMark] = useState("");
  const [bizType, setBizType] = useState("");
  const [options, setOptions] = useState([]);

  const customStyles = {
    menu: (base) => ({
      ...base,
      zIndex: 1000,
    }),
    control: (base) => ({
      ...base,
      height: 56,
    }),
  };

  const handleAdd = async () => {
    const payload = {
      parentsubsid,
      enrollmentNo,
      fullName,
      address,
      gender,
      mobile,
      wardName,
      roadName,
      landMark,
      bizType,
    };

    const result = await streetven(payload);
  };
  const handleSelect = (e) => {
    setBizType(e.value);
    console.log(e.label, "------------------");
  };

  const getAllBiz = async () => {
    const result = await allbiz();
    const optData = result.map((item) => ({
      value: item.name,
      label: item.name,
    }));

    setOptions(optData);
  };

  useEffect(() => {
    getAllBiz();
  }, []);

  useEffect(() => {
    const parentid = sessionStorage.getItem("parentsubsid");
    setParentsubsid(parentid);
    ref.current.focus();
  }, []);
  return (
    <div
      style={{
        height: "100%",
        minHeight: "82.5vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={10} sm={12} xs={12}></Grid>
        <Grid item xl={8} lg={8} md={10} sm={12} xs={12}>
          <Card className="login-card">
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <div
                    className="content-reg-div"
                    style={{
                      backgroundColor: "#008e97",
                      height: "50px",
                      margin: "-16px",
                      marginBottom: "7px",
                    }}
                  >
                    <h5>NEW VENDOR ADD</h5>
                  </div>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <Select
                    styles={customStyles}
                    onChange={handleSelect}
                    options={options}
                    ref={ref}
                  />
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <TextField
                    onChange={(e) => setEnrollmentNo(e.target.value)}
                    fullWidth
                    variant="outlined"
                    label="EnrollmentNo"
                  />
                </Grid>
                <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
                  <TextField
                    onChange={(e) => setFullName(e.target.value)}
                    fullWidth
                    variant="outlined"
                    label="Fullname"
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <TextField
                    onChange={(e) => setMobile(e.target.value)}
                    fullWidth
                    variant="outlined"
                    label="Mobileno"
                  />
                </Grid>
                <Grid
                  sx={{ textAlign: "center" }}
                  item
                  xl={3}
                  lg={3}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Gender
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="female"
                        onChange={(e) => setGender(e.target.value)}
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                        onChange={(e) => setGender(e.target.value)}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>

                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    onChange={(e) => setAddress(e.target.value)}
                    fullWidth
                    variant="outlined"
                    label="Address"
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
                  <TextField
                    onChange={(e) => setWardName(e.target.value)}
                    fullWidth
                    variant="outlined"
                    label="Wardname"
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
                  <TextField
                    onChange={(e) => setRoadName(e.target.value)}
                    fullWidth
                    variant="outlined"
                    label="Roadname"
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                  <TextField
                    onChange={(e) => setLandMark(e.target.value)}
                    fullWidth
                    variant="outlined"
                    label="LandMark"
                  />
                </Grid>
                <Grid item xl={8} lg={8} md={4} sm={4} xs={4}></Grid>
                <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
                  <Button onClick={handleAdd} variant="contained" fullWidth>
                    Submit
                  </Button>
                </Grid>
                <Grid item xl={2} lg={2} md={4} sm={4} xs={4}>
                  <Button variant="outlined" fullWidth>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
